.btnLinkname {
  height: 40px !important;
  width: 40px !important;
  padding: 0px;
  cursor: pointer;
  background-color: #012540;
}

.iconPosition {
  margin-left: 7px;
  margin-top: 6.5px;
}

.profileIcon {
  border-radius: 50%;
  height: 38.5px;
  width: 38.5px;
}

.noProfileIcon {
  border-radius: 50%;
  height: 24px;
  width: 24px;
}

.menubarIcon .MuiSvgIcon-root {
  fill: white;
}

.EditprofilePopover .MuiPopover-paper {
  background: transparent;
  overflow-y: hidden !important;
}
.EditprofilePopover .MuiPaper-elevation8 {
  box-shadow: none !important;
}

.editProfileContainer {
  margin: auto;
  width: 308px;
  margin-top: 23px;
}

.profileBox {
  background-color: #030f17 !important;
  color: #fff !important;
  position: relative;
  float: left;
  font-family: Jost-Regular, Sans-serif !important;
  font-size: 15px !important;
  top: 10px;
  padding: 20px 24px 15px !important;
}

.profileBoxArrowTop:after {
  content: " ";
  position: absolute;
  left: 209.5px;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #030f17;
}

.EditprofilePopUp {
  display: none;
}

.profileBar {
  width: 260px;
  height: 48px;
  background: #091e2a;
  border-radius: 32px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.profileBar:hover {
  background: #0D2738;
}

.profileBar:hover svg path {
  stroke: #F1F2F2;
}

.barProfilePic {
  width: 100px;
  height: 100px;
  border: 2px solid #f1f2f2;
  border-radius: 50%;
  z-index: 1;
  margin-left: -10px;
}

.barProfilePicImgNull {
  width: 50px;
  height: 50px;
  padding: 35px;
  background: #012540;
  border-radius: 50%;
}

.barProfilePicImg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.barProfileImg {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.barDetailsContainer {
  margin-top: 25px;
}

.barProfileDetails {
  justify-content: center;
  display: flex;
  font-family: Jost-Regular, Sans-serif;
}

.barProfileDetails.name {
  font-family: Jost-Regular, Sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  color: #f1f2f2;
}

.barProfileDetails.mail {
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  color: #86a2b4;
}

.appBarRoute {
  padding-left: 40px;
}

.barIconContainer {
  display: flex;
  align-items: center;
}

.barIconContainer .barIcon {
  width: 85px;
  height: 48px;
  border-color: #012540;
  background: #091e2a;
}

.onHoverIcon:hover path{
  stroke: #F1F2F2;
}

.barIconContainer .barIcon:hover {
  background: #0D2738;
  color: #f1f2f2;
}

.barIcon.leftBtn {
  border-top-left-radius: 32px !important;
  border-bottom-left-radius: 32px !important;
}

.barIcon.rightBtn {
  border-top-right-radius: 32px !important;
  border-bottom-right-radius: 32px !important;
  margin-left: -10px;
}

.barButtonContainer {
  margin-top: 32px;
}

.barButtonContainer .barButton {
  width: 126px;
  height: 44px;
  border-color: #012540;
  background: #091e2a;
  border-radius: 4px !important;
}

.barButtonContainer .barButton:hover {
  background: #0D2738;
  color: #f1f2f2;
}

.barButton:hover .MuiButton-label {
  color: #f1f2f2;
}

.barButton .MuiButton-label {
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #86a2b4;
}

.leftBtn {
  border-radius: 5px 0px 0px 5px !important;
  margin-right: 4px !important;
}

.rightBtn {
  border-radius: 0px 5px 5px 0px !important;
}

.signOutContainer {
  margin-top: 14px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 308px;
  height: 52px;
  background: #030f17;
}

.signOutContainer .signOutText {
  font-family: Jost-Regular, Sans-serif;
  padding-top: 22px;
  display: flex;
  margin: 15px auto !important;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #6a8393;
}

.signOutContainer:hover {
  background-color: #0D2738;
}

.signOutContainer:hover .signOutText {
  color: #f1f2f2;
}

.onHoverBtnbarButton .MuiButton-label {
  color: #f1f2f2;
}

.signOutText .MuiButton-label {
  margin: 15px auto !important;
}

.topHeader {
  height: 66px;
  background: #010d14;
  border-bottom: 2px solid #0f2c40;
  padding: 0px 72px;
}

.notifyIcon {
  width: "95%";
  margin-bottom: 4;
  border-radius: 4px;
}

.notifyIcon .otherBoxPopUp {
  margin-bottom: 0px !important;
}

.onHoverBtnbarButton {
  background-color: #0D2738 !important;

}

.webAddIcon {
  height: 38.23px !important;
  width: 38.23px !important;
}

.iconHightlight:hover {
  border: 1px solid #1CA9C2 !important;
  box-shadow: 0px 0px 5px #1CA9C2;
  cursor: pointer;
}

.iconHightlight:hover .webAddIconImg path {
  stroke: #1CA9C2;
  fill: #1CA9C2;
}

.iconHightlight.active .webAddIconImg path{
  stroke: #1CA9C2 !important;
}

.iconHightlight:hover .Notificationhello path {
  stroke: #1CA9C2;
  fill: #1CA9C2;
}

.iconHightlight.active {
  border: 1px solid #1CA9C2 !important;
}

.iconHightlight.active .Notificationhello path {
  stroke: #1CA9C2;
}

.webAddIconImg {
  margin-top: 11.65px;
  margin-left: 11.1px;
}

.notificationScollbar {
  width: 336px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  margin-left: 8px !important;
}

.notificationScollbar::-webkit-scrollbar {
  width: 8px;
  scrollbar-width: thin;
  border-radius: 4px;
  margin-left: 8px !important;
}

.notificationScollbar::-webkit-scrollbar-track {
  background-color: #13344A !important;
  border-radius: 6px;
  margin-left: 8px !important;
}

.notificationScollbar::-webkit-scrollbar-thumb {
  background-color: #1A3C53 !important;
  border-radius: 6px;
  border: 3px solid #1A3C53;
  margin-left: 8px !important;
}

.notifyIcon .MuiTooltip-popperArrow[x-placement*="top"] {
  display: none;
}

.notificationButton .MuiButton-root {
  min-width: 0px !important;
  padding: 0 !important;
  text-transform: none;
}

.Notificationhello {
  max-width: fit-content;
  width: 100%;
}

.ActivitiShowBtn {
  background: #0d2738;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.502);
  border-radius: 4px;
  cursor: pointer;
  width: 336px;
  height: 44px;
  margin: 10px 8px;
}

.seeActivityText {
  font-family: Jost-regular, Sans-serif !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 17px !important;
  letter-spacing: 0.1em !important;
  text-transform: uppercase !important;
  text-align: center;
  padding: 13.5px 0px !important;
  color: #f1f2f2;
}

.notificationPopUpText {
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #6a8393;
}

.MuiBadge-colorError {
  background: #A50002 !important;
  color: #FFFFFF;
}