.profileCardContainer {
  width: 800px;
  height: 293px;
  background: #030F17;
  border: 2px solid #0F2C40;
  border-radius: 10px;
  margin: 40px auto 90px;
  /* overflow: hidden; */
}

.userProfileBackgroundImg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 180px;
  background: #081b27;
  border: 0px solid #0F2C40;
  border-radius: 10px 10px 0px 0px;
}

.UserProfilebackImg {
  height: 180px;
  width: 800px;
  border-radius: 8px 8px 0px 0px;
}

.userProfilePic {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* margin-top: -90px; */
  z-index: 0;
  background: #030f17;
  width: 140px;
  height: 140px;
  border: 1.4px solid #F1F2F2;
  /* position: absolute;
    left: 44%; */
}

.userProfilepicimg {
  border-radius: 50%;
  height: 140;
  width: 140px;
}

.userProfileBackgroundDetail {
  height: 76px;
  background: #030F17;
  display: flex;
  justify-content: space-between;
  padding: 9px 20px;
  border-radius: 0 0 4px 4px;
  border-radius: 10px;
}

.userProfileDescription {
  height: auto;
  background: #030F17;
  display: flex;
  justify-content: center;
  width: 400px;
  border: 2px solid #0F2C40;
  margin: auto;
  border-radius: 10px;
  padding: 16px 140px;
  color: #86A2B4;
  text-align: center;
  word-wrap: break-word;
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}

.userAddContactContainer {
  display: flex;
  width: 240px;
  /* padding: 12px; */
  justify-content: flex-end;
  margin-top: 10px;
}

.userAddContactContainer .feedAddContactBtn {
  margin: 12px;
  width: 100%;
  height: 40px;
  background: #081b27;
  border-radius: 4px;
  margin-right: 7px;
}

.userAddContactContainer .feedEditContactBtn {
  margin: 12px;
  width: 120px;
  height: 40px;
  background: #081b27;
  border-radius: 4px;
  margin-right: 7px;
}

.feedDisableBtn {
  cursor: not-allowed !important;
}

.userAddContact {
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  margin-left: 5px;
  color: #F1F2F2;
  text-transform: none;
}

.reportAddContact {
  font-family: Jost, Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #F1F2F2;
  margin-left: 5px;
  cursor: pointer;
  text-transform: none;
}

.userThreeDotBtn {
  margin: 12px;
  width: 40px;
  height: 40px;
  background: #091E2A !important;
  border-radius: 4px;
  cursor: pointer;
}

.userThreeDotBtn .MuiButton-root {
  min-width: none;
}

.userAddContactContainer .feedAddContactBtn:hover {
  background-color: #081b27 !important;
  cursor: pointer;
}


.userThreeDotBtn .MuiTooltip-tooltip {
  margin-left: 3px;
  Width: 184px;
  padding: 2 !important;
  background: #0D2738 !important;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
}

.settingTooltip .settingTooltip-pop-up,
.menu-pop-up-arrow::before {
  padding-right: 0px !important;
  background-color: #0D2738 !important;
}

.tooltiptitle {
  Width: 184px;
  Height: 86px;
  background-color: #0D2738;
  padding-left: 0px !important;
}

.hiddenTooltiptitle {
  Width: 184px;
  Height: 86px;
  padding-left: 0px !important;

}

.tipNameUserProfile {
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #F1F2F2;
}

.userTooltipoption:hover {
  background-color: #13344A;
}

.userProfileDropDownBox {
  cursor: pointer;
  height: 45;
  display: flex;
  align-items: center;
  margin: 0 !important;
  padding: 1 !important;
}

.userProfileDropDownBox:hover {
  background-color: #13344A;
}

.userTooltipoption {
  width: 100%;
  margin: 0;
  padding: 6px;
}

.userProfileUserName {
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */
  color: #F1F2F2;
  text-shadow: 0px 0px 5px #000000;
}

.userLastName {
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #86A2B4;
  text-shadow: 0px 0px 5px #000000;
  margin-bottom: 26px;
  margin-top: 2px;

}

.userNameContainer {
  text-align: left;
  min-width: 215px;
  margin-top: 10px;
}

.userProfileReported {
  width: 799.5px;
  height: 289.5px;
  border: 2px solid #05131D;
  border-radius: 10px;
  background: rgba(5, 19, 29, 0.8);
  filter: blur(2.5px);
}

.reportIconContainer {
  position: absolute;
  top: 163px;
  right: 48%;
}

.reportTextContainer {
  position: absolute;
  top: 192px;
  right: 45%;
}

.hidenuserText {
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #406176;
}

.reporteduserText {
  font-family: Jost-Regular, Sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #861111;
}

.reportedUserBtnContainer {
  position: absolute;
  width: 144px;
  height: 30px;
  bottom: 14%;
  right: 25px;
}

.hiddenUserBtnContainer {
  position: absolute;
  height: 40px;
  bottom: 14%;
  right: 30px;
}

.contactButton {
  /* width: 124px; */
  height: 40px;
  background: #091E2A !important;
  border-radius: 4px;
  margin-right: 7px;
}

.hiddenUserContactBtn {
  width: 168px;
  height: 40px;
}

.reportedUserBtnContainer .MuiButton-text {
  padding: 6px !important;
}

.contactButton .MuiTouchRipple-root {
  visibility: hidden !important;
}

.tipButton .MuiTouchRipple-root {
  visibility: hidden !important;
}

.hiddenIconSettings .MuiTouchRipple-root {
  visibility: hidden !important;
}

.hiddenButton .MuiTouchRipple-root {
  visibility: hidden !important;
}

.userTooltipoption .MuiTouchRipple-root {
  visibility: hidden !important;
}

.feedAddContactBtn .MuiTouchRipple-root {
  visibility: hidden !important;
}

.iconThreeDotuser {
  margin: 7;
}

.hiddenButton {
  height: 40px;
  background: #091E2A !important;
  border-radius: 4px;
  margin-left: 12px !important;
  min-width: 40px !important;
}

.hiddenButton .MuiButton-root {
  min-width: none !important;
}

.hiddenIconSettings {
  margin: 7px;
}

.settingTooltip .MuiTooltip-tooltipPlacementRight {
  background-color: #0D2738;
  margin: 0 12px;
}

.settingTooltip {
  padding: 1 !important;
  background-color: #0D2738 !important;
  left: 10 !important;
  /* margin: 3 !important; */
}

.settingTooltip .menu-pop-up-arrow::before {
  padding-right: 0px !important;
  background-color: #0D2738 !important;
}

.userProfilePicContainer {
  position: relative;
  width: 153.25px;
  height: 164.02px;
  top: -80px;
}

.userInfoContainer {
  min-width: 260px;
}

.userTooltipoption .MuiButton-root:hover {
  background-color: transparent !important;
}

.userAddContactContainer .feedEditContactBtn:hover {
  background-color: #091E2A !important;
}

.EditBtnLink {
  text-decoration: none !important;
}

.basicAddImgUserprofile {
  width: 40px;
}

.noUerProfileImg {
  height: 180px;
  width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.noUserImg {
  width: 43.38px;
  height: 31.38px;

}

.tipNameUserTooltip {
  margin: 0px;
  margin-left: 5px;
  font-family: Jost-Regular, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #F1F2F2;
  text-transform: none;
}

.tipNameUserTooltip1 {
  margin: 0px;
  margin-left: 1px;
  font-family: Jost-Regular, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #F1F2F2;
  text-transform: none;
}