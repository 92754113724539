.passwordContainer {
  width: 912px;
  text-align: center;
  margin: 35px auto;
}

.changePasswordHeader {
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-top: 60px;
  margin-bottom: 36px;
  color: #6A8393;
}

.changeEmailPwdContainer {
  margin-bottom: 40px;
}

.changeEmailPwdContainer .form-control {
  padding: 10px 20px;
  color: #F1F2F2;
}

.changeEmailPwdContainer .ErrorBox{
  margin-bottom: 10px;
}

.pwdRule {
  color: #f1f2f2;
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  width: 472px;
  text-align: left;
  padding-left: 20px;
  margin: 40px 0px;
 
}

.pwdRule li {
  margin: 8px 0px;
}

.alertContainer {
  width: 335px;
  margin: 0px auto;
}

.hideAlert {
  display: none;
}

.alertContainer .MuiAlert-standardSuccess {
  background-color: #13344A;
  color: #F1F2F2;
  justify-content: center;
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}

.alertContainer .MuiAlert-icon {
  color: #cfdee8 !important;
}

.profileInnerContainer .submitButton{
  margin-bottom: 60px;
}

.profileInnerContainer .alertContainer{
  width: auto;
  margin-bottom: 20px;
}

.profileInnerContainer .alertContainer .MuiAlert-standardSuccess{
  justify-content: left;
  padding-left: 22px;
}

.passwordContainer .form-control {
  background-color: #09202D;
}

.passwordContainer .form-control:hover {
  background-color: #0D2738;
}

.passwordContainer .form-control:focus-visible {
  background: #162C3C !important;
  border: 1.4px solid #284A62;
}