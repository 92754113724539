.twoLabelContainer .form-group:first-child {
  margin-right: 12px !important;
}

.signUporInContainer .twoLabelContainer {
  margin-top: 60px;
}

.signUporInContainer.signUpbox {
  padding-top: 26px;
  padding-bottom: 40px;
}

.termsContainer {
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}

.termsContainer .MuiCheckbox-colorSecondary.Mui-checked {
    color: rgba(245, 0, 87, 0.04) !important;
}

.cookiePopupBtn:hover {
  background-color: #012540 !important;
}

.termsCheckbox {
  padding: 0px 8px !important;
  margin-top: 1;
}

.termsCheckbox .MuiSvgIcon-root {
  color: #F1F2F2;
  width: 16px;
  height: 16px;
}

.termsText {
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: hsl(180, 4%, 95%);
  margin-right: 3px;
}

.loginLinkText{
  font-family: Jost-Regular, Sans-serif;
  font-weight: 800;
  font-size: 12px;
  line-height: 17px;
  color: #f1f2f2;
  cursor: pointer;
}

/* .signUpContainer .userNameTextField{
  margin-bottom: 10px !important;
} */
.emailRule {
  color: #f1f2f2;
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  width: 472px;
  text-align: left;
  margin: 40px auto;
  padding-left: 20px;
}

.emailRule li {
  margin: 8px 0px;
}

.pwdError .ErrorBox {
  height: auto;
}

.termsContainer .MuiButton-root {
  min-width: 0;
  padding: 0;
}

.profileUpload {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  margin: auto;
  border: 1.4px solid #f1f2f2;
  margin-top: -100px;
  background: #090909;
}

.errorPicUpload {
  border-color: #B00002;
}

.regProfileInputBlock {
  opacity: 0;
  cursor: pointer;
  height: 142px;
  width: 142px;
  margin-left: 0px;
  margin-top: -140px;
}
.regNonProfileInputBlock{
  opacity: 0;
  cursor: pointer;
  height: 142px;
  width: 142px;
  margin-left: 0px;
  margin-top: -97px;  
}

.regNoprofileImg {
  margin-top: 44px;
  margin-left: 44px;
}

.regProfileImg {
  width: 140px;
  height: 140px;
  border-radius: 50%;
}

.alertContainer.regSuccessMsg {
  width: auto;
  background: #13344A;
  border-radius: 4px;
}

.hideRegSuccessmsg {
  display: none;
}

.signUporInContainer .form-control:hover {
  background-color: #0D2738;
}

.signUporInContainer .form-control:focus-visible {
  background: #162C3C !important;
  border: 1.4px solid #284A62;
}

.loginRedirectionText{
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
