@font-face {
  font-family: 'Jost-Regular';
  src: url('../font/Jost-Regular.woff') format('woff'),
    url('../font/Jost-Regular.woff2') format('woff2'),
    url('../font/Jost-Regular.svg#Jost-Regular') format('svg'),
    url('../font/Jost-Regular.eot'),
    url('../font/Jost-Regular.eot?#iefix') format('embedded-opentype'),
    url('../font/Jost-Regular.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1416px;
  }
}

.form-control {
  width: 100%;
  color: #CFDEE8;
  background-color: #081B27;
  height: 44px;
  border: none;
  padding: 11px 18px;
  font-family: Jost-Regular, Sans-serif;
  font-size: 16px;
  outline: none;
  border-radius: 4px;
}

.f15 {
  font-size: 15px;
}

/* Below code turns editing box into a deeper blue when editing */
.form-control .Mui-focused {
  /* background-color: #012540; */
  background-color: #0D2738;
  transition: 0.1s ease-in-out;
}

.form-label {
  font-family: Jost-Regular, Sans-serif;
  font-weight: 500;
  font-size: 11px;
  align-items: center;
}

.form-control::-webkit-input-placeholder {
  color: #CFDEE8;
}

.form-control::-moz-placeholder {
  color: #CFDEE8;
}

.form-control::-ms-input-placeholder {
  color: #CFDEE8;
}

.MuiInput-root::-webkit-tap-highlight-color {
  color: #CFDEE8;
}

.form-group {
  margin-bottom: 20px !important;
}

.inputError .form-control {
  border: 2px solid #B30507;
  color: #B30507;
}

/* common styles for login and signup screens Start*/
.mainContainer {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.logo {
  display: block;
  max-width: 180px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  margin-bottom: 115px;
  height: 138px;
}

.copyrightTex-container {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.copyrightText {
  color: #6a8393;
  text-align: center;
  bottom: 0;
  width: 100%;
}

.copyrightText .form-label {
  color: #6A8393;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}

.signUporInContainer {
  max-width: 478px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 65px;
  padding: 35px 100px;
  background: #030F17;
  border: 1.6px solid #0F2C40;
  border-radius: 10px;
}

.signUporInContainer .form-control {
  background-color: #09202D;
}

.signUporInContainer .form-control:focus-visible {
  background-color: #0D2738;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.32);
}

.signUporInText {
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 38px;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  color: #CFDEE8;
  text-align: center;
  margin-bottom: 25px;
}

.submitButton {
  text-align: center;
  cursor: pointer;
  justify-content: center !important;
}

.submitButton.btn:hover {
  color: #f1f2f2;
}

.submitButton button {
  width: 200px;
  height: 40px;
  border: 1.6px solid #f1f2f2 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  color: #f1f2f2;
}

.submitButton button:hover {
  background: #1B2E3A;
}

.submitButton .MuiButton-label {
  color: #f1f2f2;
  font-size: 13px;
  font-weight: 400;
  font-family: Jost-Regular, Sans-serif;
  line-height: 19px;
  letter-spacing: 0.1em;
}

/* common styles for login and signup screens End*/

/*login Error Msg Styles Start  */
.LoginErrorBox .ErrorArrowTop:after {
  display: none;
}

.LoginErrorBox .passwordFeild .ErrorArrowTop:after {
  display: block !important;
}

.LoginErrorBox .emailFeild .ErrorArrowTop:after {
  display: block !important;
}

.LoginErrorBox .ErrorBox {
  margin-bottom: 20px;
}

/*login Error Msg Styles End  */


.twoLabelContainer {
  display: flex;
}

.sideBarContainer {
  display: flex;
}

.sideBarInnerContainer {
  background: #030F17;
  border-radius: 10px;
}

.sideBar {
  width: 56px;
  background: #081B27;
  text-align: center;
}

/* Below code overwrites the Spinning loader */
._loading_overlay_overlay {
  position: fixed !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #0D2738 !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  /* border-bottom: 0px !important; */
  border-bottom: 2px solid #0D2738;
}

@media screen and (max-width: 360px) {
  .logo {
    margin-bottom: 80px;
  }

  .signUporInContainer {
    max-width: 250px;
    padding: 10px;
  }
}

@media screen and (min-width: 361px) and (max-width: 480px) {
  .logo {
    margin-top: 25px;
    margin-bottom: 80px;
  }

  .signUporInContainer {
    max-width: 300px;
    padding: 30px;
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .signUporInContainer {
    max-width: 430px;
    padding: 30px;
  }
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
  .logo {
    margin-top: 20px;
    margin-bottom: 128px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .logo {
    margin-top: 35px;
    margin-bottom: 110px;
  }

  .signUporInContainer {
    margin-bottom: 40px;
  }
}


@media screen and (max-width: 768px) {
  .twoLabelContainer {
    display: block;
  }

  .twoLabelContainer .form-group {
    width: 100% !important;
  }
}

@media screen and (min-width:1920px) and (max-height: 1080px) {
  .logo {
    margin-top: 180px;
    margin-bottom: 87px;
  }
}

@media screen and (max-height:720px) {
  .logo {
    margin-top: 20px;
    margin-bottom: 67px;
  }
}

#scrollableDiv {
  overflow: scroll;
  margin-bottom: 45px;
}

.custom-scroll{
  scrollbar-width: auto;
  overflow-x: hidden !important;
  scrollbar-color: #1A3C53 #13344A;
  
}