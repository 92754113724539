.login_card {
  max-width: 580px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: #061621;
  padding: 35px 70px 25px 70px;
}

.mainContainerlogin_card {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.logInText {
  font-family: Jost-regular, Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 38px;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  color: #6A8393;
  text-align: center;
  margin-bottom: 35px;
  margin-top: 25px;
}

.loginContainer {
  max-width: 478px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 65px;
  padding: 35px 100px;
  background: #030F17;
  border: 1.6px solid #0F2C40;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.4));
}

.loginContainer .form-control {
  background-color: #09202D;
}

.loginFormInputFeild {
  border-radius: 4px;
}

.loginLinksBlock {
  display: flex;
  justify-content: space-between;
  color: #6A8393;
  margin-top: 10px;
  padding: 0px 20px;
}

.submitButtonLogin {
  text-align: center;
  margin: 40px 100px 12px 100px;
  cursor: pointer;
  justify-content: center !important;
}

.loginTextsignUp {
  font-family: Jost-regular, Sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: #F1F2F2;
  margin-bottom: 5px;
}

.forgetPasswordLink {
  cursor: 'pointer';
  text-decoration: none;
  color: #6A8393;
  font-family: Jost-regular, Sans-serif;
  font-weight: 500;
  font-size: 11px
}

.submitButtonLogin.btn:hover {
  color: #f1f2f2;
}

.submitButtonLogin button {
  width: 200px;
  height: 40px;
  border: 1.6px solid #f1f2f2 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  color: #f1f2f2;
}

.submitButtonLogin .MuiButton-label {
  color: #f1f2f2;
  font-size: 13px;
  font-family: Jost-regular, Sans-serif;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

}

.rememberMeCheckbox {
  margin-right: 0px !important;
}

.rememberMeCheckbox .MuiTypography-body1 {
  font-family: Jost-regular, Sans-serif;
  font-weight: 500;
  font-size: 11px;
  color: #6A8393;
}

.rememberMeCheckbox .MuiCheckbox-root {
  padding: 0px;
}

.rememberMeCheckbox .MuiCheckbox-colorSecondary.Mui-checked {
  color: rgba(245, 0, 87, 0.04) !important;
}

.rememberMeCheckbox .MuiSvgIcon-root {
  color: #6A8393;
  width: 15px;
  height: 15px;
  padding-right: 6px;
}

.labelRememberMe .MuiCheckbox-root {
  padding-top: 6px !important;
}

.login_rights {
  color: #6a8393;
  text-align: center;
  margin-top: -5px;
  font-size: 12px;
  bottom: 0;
  position: fixed;
  width: 100%;
  left: 0;
}

.error {
  color: #f67054;
}


.signUphyperLink {
  font-family: Jost-regular, Sans-serif;
  font-weight: 800;
  color: #f1f2f2 !important;
  text-decoration: none;
  margin-left: 3px;
}

.loginBtn {
  width: 202px !important;
}

.loginCopyRightText {
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 32px;
  background: rgba(8, 27, 39, 0.8);
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
}

.copyrightTextLogin {
  color: #6a8393;
  text-align: center;
  bottom: 0;
  width: 100%;
}

.copyrightTextLogin .form-label {
  margin: 8px;
  font-family: Jost-regular, Sans-serif;
  font-style: normal;
  color: #6A8393;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}

.ForgotPasswordModal {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 580px;
  max-height: 250px;
  height: auto;
  margin: auto;
  background: #061621
}

.forgotPasswordHeading {
  color: #fff;
  font-family: Jost-regular, Sans-serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 38px;
  letter-spacing: 0.09em;
  text-align: center;
  margin: 20px
}

.formInputFeild {
  margin: 10px 100px;
}


@media only screen and (max-width: 767px) {
  .formInputFeild {
    margin: auto;
  }

  .loginLinksBlock {
    margin: 10px auto;
  }
}

.loginContainer .form-control:hover {
  background-color: #0D2738;
}

.loginContainer .form-control:focus-visible {
  background: #162C3C !important;
  border: 1.4px solid #284A62;
}