.feedContainer {
  width: 800px;
  text-align: center;
  margin: 35px auto;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #000000;
}

.feedBackgroundImg {
  position: relative;
  height: 171px;
  background: #081b27;
  border: 1px solid #030f17;
}

.feedBackgroundDetail {
  height: 51px;
  background: #030f17;
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
}

.feedNameContainer {
  text-align: left;
  width: 300px;
}

.feedUserName {
  font-family: Jost-Regular, Sans-serif;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: #f1f2f2;
  margin: 0px;
}

.feedNickName {
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #6a8393;
  margin: 0px;
}

.feedProfilePic {
  width: 169px;
  height: 169px;
  border-radius: 50%;
  border: 1.4px solid #f1f2f2;
  margin-top: -65px;
  z-index: 0;
  background: #030f17;
}

.basicFeedPic {
  border-radius: 50%;
  height: 169;
  width: 169;
}

.basicFeedPicSmall {
  height: 56px !important;
  width: 56px !important;
  padding: 30px;
  margin-top: 25px;
}

.UserProfilebasicAddImg {
  height: 170px;
  width: 794px;
}

.feedAddContactContainer {
  display: flex;
  width: 300px;
  justify-content: flex-end;
}

.feedAddContactContainer .feedAddContactBtn {
  width: 124px;
  height: 35px;
  background: #081b27;
  border-radius: 4px;
  margin-right: 7px;
}

.feedAddContactContainer .feedAddContactBtn:hover {
  width: 124px;
  height: 35px;
  background: #012540;
  border-radius: 4px;
  margin-right: 7px;
}

.feedAddContactBtn .MuiSvgIcon-root {
  fill: white;
  height: 19px;
  margin-right: 5px;
}

.feedAddContact {
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #f1f2f2;
  text-transform: capitalize;
  margin: 0px;
}

.feedAddContactContainer .feedSettings {
  width: 40px;
  height: 35px;
  background: #081b27;
  border-radius: 4px;
  padding: 0px;
  color: #f1f2f2;
}

.feedSettingsIcon {
  margin: 5px;
  margin-top: 0;
  padding-top: 4px;
  color: #f1f2f2;
}

.feedTitle {
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #F1F2F2;
  margin: 0px;
}

.feedUser {
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #F1F2F2;
  margin: 0 !important;
}

.feedDate {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #86A2B4;
  margin: 0 !important;
}

.feedThreeDots {
  margin-right: 2rem;
  cursor: pointer;
}

.feedApproveDisapproveGroup,
.feedShareSaveGroup {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #6a8393;
  padding: 0px 2rem;
}

.Feed-footer-icon {
  margin-left: 1rem;
  margin-right: 1rem;
}

.submit-footer-icon {
  width: 32px;
  height: 32px;
}

.discard-footer-icon {
  width: 32px;
  height: 32px;
}

.cursorPointer {
  cursor: pointer;
}

.feedFilterText {
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  cursor: pointer;
  color: #6a8393;
  margin: 0;
  margin-right: 10.12px;
}

.feedFilterDiv {
  max-width: 680px;
  display: flex;
  color: white;
  width: auto;
  margin: auto;
  justify-content: flex-end;
}

.FeedIdeaBox {
  max-width: 100%;
  width: auto;
  border-radius: 10px;
  padding: 20px 24px 32px;
  margin: auto 0px;
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  color: #f1f2f2;
}

.parentoffeedPrivacyDiv {
  position: relative;
  border-radius: 10px;
  background-color: #030F17;
  min-width: 680px;
  height: auto;
  border: 2px solid #0F2C40;
}

.feedPrivacyDiv {
  z-index: 1;
  position: absolute;
  height: 19px;
  width: 19px;
  right: 15;
  top: 14;
}

.feedHashtag {
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #86A2B4;
  margin: 0px;
}

#title-box {
  background-color: #081b27;
  padding-left: 0px !important;
}

#title-feedFilter {
  background-color: #0d2738;
  padding-left: 0px !important;
}

#title-box-feedfilter {
  background-color: #0d2738;
  padding-left: 12px !important;
}

.addDividerDropdown {
  border-radius: 0px;
  /* border-bottom: 1px solid #284a62; */
  margin: 0px 8px !important;
}

.feedFilterDropDownBox {
  border-radius: 4px;
  margin: 0px !important;
}

.feedFilterDropDownBox:hover {
  background-color: #13344A;
}

.menu-title {
  font-family: Jost-Regular, Sans-serif !important;
  margin: 10px;
  display: flex;
  align-items: center;
  font-size: 13px
}

.menu-icon {
  margin-right: 10px;
  height: 19px
}

.text-white {
  color: white;
}

.MuiTooltip-popperArrow[x-placement*="right"] .MuiTooltip-arrow {
  margin-top: 11px;
}

.feedprofileImage {
  position: absolute;
  width: 48px;
  height: 48px;
  z-index: 1;
  left: -4rem;
  top: 0rem;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 16px;
}

.mainProfilePic {
  border-radius: 50%;
  border: 1px solid #f1f2f2;
  height: 46.5px;
  width: 46.5px;
  z-index: 5;
  filter: drop-shadow(0px 23px 0px rgba(0, 30, 53, 0.3));
}

.iteratedUser {
  height: 46px;
  width: 46px;
  border-radius: 50%;
  border: 1px solid #f1f2f2;
  left: 0.5px;
  background-color: #031017;
}

.iteratedUser.dummy {
  position: absolute;
  top: 0px;
  filter: drop-shadow(0px 37px 12px rgba(0, 30, 53, 0.7));
}

.iteratedUser.one {
  position: absolute;
  z-index: -1;
  top: 23px;
  filter: drop-shadow(0px 42px 12px rgba(0, 30, 53, 0.8));
}

.iteratedUser.two {
  position: absolute;
  z-index: -2;
  top: 51px;
  filter: drop-shadow(0px 38px 12px rgba(0, 30, 53, 1));
}

.iteratedUser.three {
  position: absolute;
  z-index: -3;
  top: 75px;
}

.iteratedUser.three.threeNone {
  filter: drop-shadow(0px 38px 12px rgba(0, 30, 53, 1));
}

.iteratedUser.four {
  position: absolute;
  z-index: -4;
  top: 95px;
}

.iteratedUser.four.fourNone {
  display: none;
}

.profileIconMore {
  position: absolute;
  top: 135px;
  left: 20px;
}

.lastProfilePic {
  border-radius: 50%;
  border: 1px solid #f1f2f2;
  height: 44px;
  width: 44px;
  z-index: 5;
  position: absolute;
  top: 172px;
  left: 0.5px;
}

.noProfileImg {
  width: 30px;
  height: 30px;
  padding: 8px;
}

.feedTooltip .MuiTooltip-popper {
  width: 9% !important;
  min-width: 170px !important;
}

.parentofFeedFilter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.feedDropDownIconTransitionOpen {
  transition: all 0.2s ease-in-out;
  transform: rotate(-90deg);
}

.feedDropDownIconTransitionOpen path {
  fill: white;
}

.feedDropDownIconTransitionClose {
  transition: all 0.2s ease-in-out;
  transform: rotate(0deg);
}

.feedDropDownIconTransitionClose path {
  fill: #6a8393;
}

.feedsmaller-input {
  width: 308px;
  max-width: 345px;
}

.onHoverTextField {
  position: absolute;
  gap: 1rem;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  z-index: 1000;
  background: rgba(0, 43, 75, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.BlurLayer {
  z-index: 2;
  position: absolute;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(2px);
  border-radius: 4px;
}

.onHovertext {
  color: #f1f2f2;
  opacity: 1;
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  line-height: 61px;
}

.hideBlurLayer {
  z-index: 2;
  position: absolute;
  height: -webkit-fill-available;
  width: 96%;
  backdrop-filter: blur(0px);
  border-radius: 4px;
  background: rgba(3, 15, 23, 0.92);
}

.blurMsg {
  color: #86A2B4;
  opacity: 1;
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}


.reportedMsg {
  color: #B00002;
}

.onBlurTextField {
  height: 100%;
  top: 0;
  left: 0;
  gap: 1rem;
  position: absolute;
  opacity: 1;
  flex-direction: column;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  background: rgba(0, 43, 75, 0.25);
  width: 100%;
  backdrop-filter: blur(1px);
}
@supports (-moz-appearance: none) {
  /* Firefox-specific styles */
  .onBlurTextField {
    backdrop-filter: blur(1px);
    background: rgba(3, 15, 23, 0.92);
  }
}
.feedThreeDots:hover path {
  fill: #fff;
}

.feedEditedText {
  margin-left: 6px;
  font-size: 13px;
  line-height: 19px;
  color: #8fa8b8;
}

.Hidediv {
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 44px;
  text-align: center;
  color: #f1f2f2;
  background-color: #031017;
  padding: 2rem 0;
  border-radius: 4px;
  box-shadow: 0px 0px 5px #000000;
}

.feedUndoBtn {
  min-width: 160px !important;
  height: 40 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  position: relative !important;
  border: 1.6px solid #F1F2F2 !important;
}

.feedUndoText {
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #F1F2F2;
  margin: 0px 0px 0px 8px;
}

.feedHideText {
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 400;
}



.feedHoverApprove:hover circle {
  stroke: #f1f2f2;
}

.feedHoverApprove:hover path {
  stroke: #f1f2f2;
}

.MuiTooltip-tooltipPlacementRight {
  box-shadow: 0px 0px 5px #000000 !important
}

.feedHoverUndo:hover path {
  fill: #012540;

}

.feedHoverUndo:hover circle {
  stroke: #012540;
  fill: #012540;
}

.feedVoted path {
  fill: #012540;
  stroke: #F1F2F2;
}

.feedVoted circle {
  stroke: #f1f2f2;
  fill: #012540;
}

.feedHoverDisapprove:hover circle {
  stroke: #f1f2f2;
}

.feedHoverDisapprove:hover path {
  stroke: #f1f2f2;
}

.feedHoverSave:hover circle {
  stroke: #f1f2f2;
}

.feedHoverSave:hover path {
  stroke: #f1f2f2;
}


.feedSave path {
  stroke: #f1f2f2;
}

.feedSave circle {
  fill: #2B4251;
  stroke: #f1f2f2;
}


.ShareIcon:hover circle {
  stroke: #f1f2f2;
}

.ShareIcon:hover path {
  stroke: #f1f2f2;
}


.AlreadyShareIcon path {
  stroke: #f1f2f2;
}

.AlreadyShareIcon circle {
  fill: #2B4251;
  stroke: #f1f2f2;
}

.feedPrivacyDiv:hover path,
.feedPrivacyDiv:hover line {
  fill: #f1f2f2;
  stroke: #f1f2f2;
}

.menu-title svg,
.feedPrivacyDiv svg {
  height: 19;
  width: 19;
}

.onHoverIterationUsers {
  position: relative;
}

.onHoverIterationUsers .iteratedUser {
  display: block;
  margin-top: 7px;
}

.iterUserName {
  margin-left: 20px;
  font-size: 18px;
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 400;
}

.feedShareSaveGroup .MuiAlert-standardSuccess {
  background-color: #0D2738 !important;
  color: #f2f2f2 !important;
}

.feedShareSaveGroup .MuiTooltip-tooltip {
  padding: 0;
}

.toolTipNames {
  display: flex;
  align-items: center;
  font-size: 13;
  text-transform: none;
  max-width: 184px;
  width: 184px;
}

.tipName {
  margin: 0px;
  margin-left: 5px;
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #f1f2f2;
  text-transform: capitalize;
}

.textArea-my-class {
  background-color: #012540 !important;
}

/* hidden iterationPopup */

.hiddeniterationPopupContainer .MuiDialog-paperScrollPaper {
  max-width: 680px;
  width: 560px;
  max-height: 620px;
  background: #061621;
  border: 1.5px solid #193a51;
  border-radius: 8px;
}

.hiddeniterationPopupContainer .MuiDialog-paper {
  margin: 0 !important;
  padding: 40.5px 59px !important;
}

.hiddeniterationPopupContainer .MuiDialog-paper .MuiIconButton-label {
  width: 20px;
  height: 20px;
}

.hiddeniterationPopupContainer .MuiDialogContent-root {
  margin: 20px;
  text-align: center;
}

.hiddeniterationPopupContainer .MuiDialogContentText-root {
  font-family: Jost-Regular, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.04em;

  color: #ffffff !important;
}

.hiddeniterationPopupContainer .MuiDialogActions-root {
  justify-content: center;
}

.hiddeniterationPopupContainer .popUpAction .MuiButtonBase-root {
  border: 1.6px solid #f1f2f2;
  border-radius: 4px;
  width: 160px;
  height: 40px;
}

.hiddeniterationPopupContainer .MuiButton-label {
  font-family: Jost-Regular, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #f1f2f2 !important;
}

.hiddeniterationPopupContainer .MuiDialogActions-spacing> :not(:first-child) {
  margin-left: 28px;
}

.hiddeniterationPopupContainer .MuiDialogTitle-root .MuiTypography-h6 {
  font-family: Jost-Regular, sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #86a2b4;
}

.hiddeniterationPopupContainer .close_icon_popup {
  position: absolute;
  top: 10.76px;
  right: 25.76px;
  color: #86a2b4;
}

.feedFilterDiv .MuiTooltip-tooltip {
  padding: 0px !important;
}

.feedFilterDiv .MuiTooltip-tooltipPlacementRight {
  margin: 0px 6px !important;
  background-color: #0d2738 !important;
  width: 140px;
  box-shadow: none !important;
}

.feedFilterDropDownBox .menu-title {
  text-transform: uppercase !important;
  font-family: Jost-Regular, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #86A2B4;
  padding: 10px;
  margin: 0px;
}

.feedFilterDropDownBox .menu-title:hover {
  color: #F1F2F2;
}

.postLabelBox {
  display: flex;
  background: #091E2A;
  border-radius: 4px 4px 0px 0px;
  padding: 12px 16px;
  margin-bottom: 2px;
  justify-content: space-between;
  align-items: center;
}

.postLabelBox:hover {
  background-color: #0D2738;
}

.activeContextBoxBackcolor {
  background-color: #0D2738 !important;

}

.postLabelTitle {
  font-family: Jost-Regular, Sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #F1F2F2;
  margin: 0px;
}

.feedPostWrapper {
  display: flex;
  margin: auto;
  width: 680px;
  margin-top: 20px;
}

.feedContent .MuiInput-multiline {
  padding: 22px 20px;
  flex-direction: column;
  letter-spacing: normal;
}

[data-title]:hover::after {
  content: attr(data-title);
  position: absolute;
  margin-top: 4px;
  top: 100%;
  left: 50%;
  width: auto;
  height: 14px;
  transform: translateX(-50%);
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
  text-transform: math-auto;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #0D2738;
  color: #F1F2F2;
  position: absolute;
  padding: 4px 12px;
  bottom: -2.7em;
  left: -50%;
  white-space: nowrap;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
  border-radius: 2px;
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-transform: capitalize;
}

[data-title] {
  position: relative;
}

.iterationListContainer {
  min-width: 296px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  overflow: hidden;
  margin: 1.5px 8px 0px 1.5px;
}

.iterationListSubContainer .iterationListContainer:last-child {
  margin-bottom: 1.5px;
}

.iterationListContainer:hover {
  outline: 1.5px solid #1193AA;
  border-radius: 4px;
}

.itrationListProfileDiv {
  width: 58px;
  height: 58px;
  background: #030F17;
  border-radius: 4px 0px 0px 4px;
}

.iterationListProfileImg {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #F1F2F2;
  margin: 9.68px 10px;
  z-index: 0;
}

.iterationListDescDiv {
  margin-left: 2px;
  width: 100%;
  height: 58px;
  background: #030F17;
  border-radius: 0px 4px 4px 0px;
}

.IterationListUsername {
  margin-top: 20px;
  margin-left: 12px;
  font-family: Jost-Regular, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #F1F2F2;
}

.IteartionListDate {
  margin-top: 20px;
  font-family: Jost-Regular, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #86A2B4;
}

.hiddenIteartionDiv {
  background: #05131D !important;
}

.currentViewDiv {
  margin-left: 2px;
  width: 176px !important;
  height: 58px;
  background: #0E2230 !important;
}

.currentViewProfileDiv {
  background: #0E2230;
  width: 58px;
  height: 56px;
  border-radius: 4px 0 0 4px;
}

.blurName {
  opacity: 0.6;
  margin-top: 6px !important;
}

.txtHiddenIteartion {
  font-family: Jost-Regular, sans-serif;
  font-weight: 500;
  font-size: 7px;
  line-height: 10px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #5B7C92;
  margin-top: 14px;
  margin-left: 12px;
}

.txtReportedIteration {
  color: #B00002 !important;
}

.txtOriginalIdea {
  font-weight: 700 !important;
  color: #1CA9C2 !important;
}

.mt3 {
  margin-top: 3px !important;
}

.op3 {
  opacity: 0.3;
}

.op6 {
  opacity: 0.6;
}

.hiddenProfileImg {
  width: 16px;
  z-index: 10;
  position: relative;
  height: 16px;
  margin-top: -38px;
  margin-left: 22px;
}

.shareDiv button {
  width: 28px;
  height: 27px;
  background-color: #0E2230;
  border: none;
  cursor: pointer;
}

.subShareDiv button:first-child {
  margin: 0px 2px 2px 2px;
}

.subShareDiv button:nth-child(2) {
  margin-bottom: 2px;
}

.shareDiv button:hover {
  background-color: #13344A;
}

.shareDiv img {
  width: 16px;
  height: 16px;
  align-self: center;
}

.shareDiv p {
  font-family: Jost-Regular, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 10px;
  text-align: center;
  color: #86A2B4;
  margin: 0;
}

.iterationListSubContainer {
  width: 311px;
  background: transparent;
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: #1A3C53 #13344A;
  scrollbar-width: auto;
}

.iteartionListBtnContainer span {
  margin: 0px 11px;
  color: #86A2B4;
  font-family: Jost-Regular, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  cursor: pointer;
}

.iteartionListBtnContainer {
  margin-top: 12px;
  width: 310px;
  display: flex;
  justify-content: space-between;
}

.currentViewBorder {
  outline: 1.5px solid #1193AA;
  border-radius: 4px;
}

.feedShare .MuiAlert-icon .MuiSvgIcon-fontSizeInherit {
  font-size: inherit;
  align-items: center;
  padding: 6px 0px;
  width: 10px;
  height: 10px;
}

.feedShare .MuiTooltip-popper {
  min-width: 120px;
  top: 2px !important;
}

.feedShare .MuiAlert-message span {
  font-family: Jost-regular, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0px;
  color: #F1F2F2;
}

.feedSave .MuiTooltip-popper {
  width: 105px;
}

.feedShare .MuiAlert-standardSuccess {
  padding: 0px 12px;
  height: 22px;
}

.feedShare .MuiAlert-icon {
  padding: 0px;
  margin-right: 4px;
}

.feedShare .MuiAlert-message {
  padding: 4px 0px !important;
}

.feedShare .MuiTooltip-tooltipPlacementBottom {
  margin: -10 !important;
  width: 126px;
}

.feedSaved .MuiTooltip-tooltipPlacementBottom {
  margin: -9px 5px !important;
  width: auto !important;
}

.onHoverClicktoEditTest {
  font-family: Jost-regular, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  /* identical to box height */

  text-align: right;
  margin: 0;
  margin-left: 6.5px;
  color: #6D95AF;
}

.onHoverClicktoEditTestContainer {
  display: flex;
}

.feedContent {
  background-color: #091E2A;
}

.feedContent .form-control .Mui-focused {
  background: #091E2A !important;
  border-radius: 0px 0px 4px 4px;

}

.activeContextBox {
  background: #0D2738 !important;
  border: 1.4px solid #284A62;
  border-radius: 4px 4px 0px 0px
}

.activeContainer {
  background: #0D2738 !important;
  border: 1.4px solid #284A62;
  border-radius: 0px 0px 4px 4px;
}

.plusTooltipoption .MuiTooltip-tooltipPlacementRight {
  margin: 0 0px 0px 26px;
}

.previewImageContext {
  width: 100%;
  object-fit: cover;
  height: 210px;
  overflow: hidden;
}

.center .MuiButton-root {
  min-width: 16px;
}

.MuiInput-underline:before {
  display: none;
}

.profilePicLast,
.twiImgProfilePicLast {
  border-radius: 50%;
  border: 1px solid #F1F2F2;
  height: 34.5px;
  width: 34.5px;
  z-index: 5;
  position: absolute;
  top: 90px;
  left: 6px;
}

.twiImgProfilePicLast {
  top: 74px;
}

.MoreProfileIcon {
  position: absolute;
  top: 50px;
  left: 23px;
}

.IteartionCount {
  position: absolute;
  min-width: 24px;
  height: 16px;
  padding: 2px 6px;
  background: #13344A;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  font-family: Jost-Regular, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  cursor: pointer;
  color: #86A2B4;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  margin-top: 10px;
  margin-left: 6px;
}

.IteartionCount:hover {
  min-width: 25px;
  height: 17px;
  border: 1px solid #284A62;
  font-size: 12px;
  line-height: 17px;
  color: #F1F2F2;
  margin-left: 5px;
}

.profileBoxMainDiv {
  width: 300px !important;
  height: 100px !important;
  border-radius: 4px;
  border: 2px solid #0F2C40;
  background: #0B2434;
}

.diff-result {
  color: white;
  margin: 0px;
  font-family: Jost-Regular, sans-serif;
  font-weight: 400;
  line-height: 20px;
  text-align: justify;
  word-wrap: break-word;
}

.textAdded {
  color: #51E872;
  word-wrap: break-word;
}

.textDeleted {
  color: #E83B3B;
  text-decoration: line-through;
  word-wrap: break-word;
}

.mainFeedContent {
  position: relative;
  cursor: pointer
}

.mainFeedContentTitle {
  position: relative;
  cursor: pointer
}

.colorText {
  width: 593px !important;
  min-height: 100px !important;
  padding: 22px 20px !important;
  color: #CFDEE8;
  background-color: #081B27;
  border: none;
  font-family: Jost-Regular, Sans-serif;
  outline: none;
  border-radius: 4px;
  word-wrap: break-word;
  line-height: 20px;
  font-weight: 400;
}

.probSolWidth {
  width: 268px !important;
}

.feedOrgIdeaText {
  margin: 0px 0px 6px;
  font-family: Jost-Regular, sans-serif;
  font-weight: 700;
  font-size: 9px;
  line-height: 13px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #1CA9C2;
}

.feedOrgIdeaBox {
  border: 2px solid #1193AA;
}

.form-control-feed {
  width: 100%;
  color: #CFDEE8;
  background-color: #091E2A;
  height: 44px;
  border: none;
  padding: 11px 18px;
  font-family: Jost-Regular, Sans-serif;
  font-size: 16px;
  outline: none;
  border-radius: 0px 0px 4px 4px;
}

.form-control-feed .Mui-focused {
  background-color: #0D2738;
  transition: 0.1s ease-in-out;
}

.privacyIcon {
  border: none;
  background: #030F17;
  border-radius: 4px;
  height: 36px;
  width: 38px;
  margin-bottom: 2px;
  display: block;
  cursor: pointer;
}

.privacyIconDisabled {
  cursor: auto;
}

.privacyTooltip {
  margin: 0px !important;
  margin-left: -38px !important;
  width: 150px;
  background: #0D2738 !important;
  padding: 2px !important;
}

.privacyTooltip .menu-title {
  padding-left: 20px;
  text-transform: capitalize !important;
}

.privacyTooltip #title-box {
  background: #0D2738;
}

.privacyTooltip .menu-icon {
  margin-right: 8px;
}

.privacyTooltip .feedFilterDropDownBox:hover {
  background-color: #13344A;
}

.defaultListArrow {
  height: 10px;
  top: 2px;
  position: relative;
}

.ProfileBoxImageBig {
  position: absolute;
  top: -4px;
  right: -45;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: inherit;
}

.currentOrigViewDiv {
  margin-left: 2px;
  width: 188px !important;
  height: 58px;
  background: #0E2230 !important;
}

.SubContainerNoScroll {
  width: 311px;
  background: transparent;
  max-height: 500px;
}

.currentViewDivLong {
  margin-left: 2px;
  width: 178px !important;
  height: 56px;
  background: #0E2230 !important;
}

.hoverProfileBoxImg {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 1.5px solid #F1F2F2;
  background-color: #091b27;
  z-index: 1;
}

.hoverhiddenProfileImg {
  z-index: 99;
  position: relative;
  top: -65;
  right: -45;
  height: 24px;
  width: 24px;
}

.currentViewBtnTxt {
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 10px;
  margin: 0px 5px;
  color: #86A2B4;
}

.smallIcon {
  height: 8px !important;
  width: 8px !important;
}

.subShareDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.subShareDiv.bottom button {
  margin-bottom: 0px;
}

.firstCustomIconBtn.editItr {
  margin-bottom: 4px;
}

.ProfileBoxFullName {
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #F1F2F2;
}

.ProfileBoxUserName {
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #6A8393;
}

.AddContactDiv {
  margin-top: 8;
  width: 100px;
  max-width: 100;
  height: 28px;
  background: #030F17;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
}

.disabledBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8;
  width: 110px;
  max-width: 110;
  height: 28px;
  background: #030F17;
  border-radius: 4px;
  cursor: not-allowed;
  border: none;
  filter: blur(0.5px);
}

.IdeaDiv {
  margin-top: 8;
  width: 100px;
  max-width: 100;
  height: 28px;
  background: #030F17;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.AddContactText {
  margin-left: 4px;
  line-height: 16px;
  color: #F1F2F2;
}

#iterationlist .MuiTooltip-tooltip {
  padding: 4 !important;
  margin: 0px 0px 35px 0px !important;
}

#iterationlist .MuiTooltip-popperArrow[x-placement*="bottom"] .MuiTooltip-arrow {
  margin-top: 0.95rem;
}

#iterationlistOriginalidea .MuiTooltip-tooltip {
  padding: 3px !important;
  left: -10px;
  padding-bottom: 0 !important;
}

#iterationlistOriginalidea.MuiTooltip-popperArrow[x-placement*="bottom"] .MuiTooltip-arrow {
  margin-left: 20px !important;
}

#iterationlistCurrentView .MuiTooltip-tooltip {
  padding: 4 !important;
  left: -10px;
  padding-bottom: 0 !important;
}

#iterationlistCurrentView.MuiTooltip-popperArrow[x-placement*="bottom"] .MuiTooltip-arrow {
  margin-left: 20px !important;
}

.iterationListSubContainer::-webkit-scrollbar {
  width: 6px;
  scrollbar-width: thin;
  border-radius: 4px;
  margin-left: 8px !important;
}

.iterationListSubContainer::-webkit-scrollbar-track {
  background-color: #13344A !important;
  border-radius: 6px;
  margin-left: 8px !important;
}

.iterationListSubContainer::-webkit-scrollbar-thumb {
  background-color: #1A3C53 !important;
  border-radius: 6px;
  border: 3px solid #1A3C53;
  margin-left: 8px !important;
}

.feedSideIcons #customIconButton {
  border-radius: 0px 4px 4px 0px;
}

.filterItrText {
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
  cursor: pointer;
  color: #86A2B4;
  margin: 0;
}

.filterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.filterContainer .closeBtn {
  margin-top: 2px;
}

.feedSideIcons #customIconButton:first-child {
  margin-top: 16px;
}

.vertical-line {
  border-left: 1px solid #7E97A7;
  height: 48.5%;
  margin-top: 4px;
}

.feedTitleBox .MuiInputBase-multiline .MuiInputBase-input {
  min-height: 22px !important;
  text-transform: uppercase;
  width: 589px;
  line-height: 22px;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 1px;
}

.feedTitleBox .form-control-feed {
  border-radius: 4px;
}

.feedTitleBox .MuiInputBase-multiline {
  padding: 7.5px 0px;
}

.feedTitleBox {
  text-align: left;
  align-items: flex-start;
  background: transparent;
}

.feedTitleBox .Mui-focused {
  border: 1.4px solid #284A62;
  padding: 6.5px 20px !important;
}

.activeContextBoxBackcolor .feedTitleBox {
  width: auto;
}

.activeContextBoxBackcolor .feedTitleBox .MuiInputBase-multiline {
  padding: 7.5px 20px;
}

.mainFeedContent .colorText {
  font-size: 14px;
}

.mainFeedContentTitle .feedTitle {
  padding: 7.5px 0px;
}

.mainFeedContentTitle .diff-result {
  height: 25px;
  line-height: 25px;
}

.mainFeedContentTitle .multiline {
  line-height: 22px;
}

.mainFeedContentTitle .activeContextBox {
  border: none;
}

.mainFeedContentTitle .activeContainer {
  border: none;
}