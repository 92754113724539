/*CustomErrorAlert styles start*/
.ErrorBox {
  max-width: -moz-available;
  background-color: #B00002 !important;
  color: #fff !important;
  position: relative;
  float: left;
  height: 33px;
  font-family: Jost-Regular, Sans-serif !important;
  font-size: 15px !important;
  top: 10px;
  width: -webkit-fill-available !important;
  margin-bottom: 5px;
}

.ErrorBox.ErrorArrowTop:after {
  content: " ";
  position: absolute;
  left: 15px;
  top: -9px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #B00002;
}

/*CustomErrorAlert styles End*/

/*CustomPassword file styles start*/
.passwordInputField {
  z-index: -1;
}

.icon_pass {
  width: 18px;
  float: right;
  cursor: pointer;
  z-index: 4;
  position: relative;
  margin-left: -36px;
  margin-top: 13px;
  margin-right: 18px;
}

/*CustomPassword file styles End*/

/*CustomSearch file styles start*/
.searchBarContainer {
  width: 37rem;
  height: 40px;
  left: 664px;
  top: 13px;
  border: 1.4px solid #86A2B4;
  box-sizing: border-box;
  border-radius: 42px;
  display: flex;
}

.searchBar {
  width: 22.4rem;
  margin-left: 10px;
  color: white !important;
}

@media screen and (min-width: 1400px) {
  .searchBar {
    width: 29.4rem;
  }
}

.settingsIcon {
  Width: 20px;
  Height: 20px;
  margin: auto;
  margin-left: 20px;
}

.searchIconOutline {
  border: 1.4px solid #F1F2F2;
  box-sizing: border-box;
  border-radius: 42px;
  width: 64px;
  height: 40px;
  margin-top: -1.5px;
  margin-right: -1px;
  background-color: #F1F2F2;
}

.searchIcon {
  /* margin-left: 19px; */
  height: 14.33px;
  width: 14.33px;
}

.searchIconOutline .searchIcon {
  margin-left: 0px;
}

/*CustomSearch file styles End*/

/*Custom Menu Side bar Start */
.SideIcon {
  display: block;
  margin-left: auto;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 56px;
  height: 48px;
  background: #030F17;
  margin-top: 2px;
  margin-bottom: 2px;
}

.SideIcon.active {
  width: 56px;
  height: 48px;
  background-color: #13344A;
}

.SideIcon.active .iconColor {
  fill: #F1F2F2 !important;
  margin-top: 14px;
}

.iconColor {
  fill: #86A2B4 !important;
  font-size: 18px !important;
  margin-top: 14px;
}

/*Custom Menu Side bar End */
/*Custom Profile  Start */
.profileInnerContainer {
  width: 480.23px;
  padding: 0px 159px;
  border: 1.6px solid #0F2C40;
}

/*Custom Profile  Start */

/*CustomSearch start*/
.searchPopUpContainer .MuiDialog-paper {
  width: 596px;
  overflow-x: hidden;
  margin: 0px;
  background: #061621;
  border: 1.5px solid #193A51;
  border-radius: 8px;
}

.searchPopUpContainer .MuiSvgIcon-root {
  color: #8FA8B8;
  height: 19px;
}

.searchPopUpContainer .MuiDialogContent-root {
  padding: 24px 59px;
}

.searchClose {
  margin-right: 0px !important;
  padding-right: 30px !important;
  padding-top: 30px !important;
}

.searchTitle .MuiTypography-h6 {
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  text-transform: uppercase;
  color: #6A8393;
  text-align: center;
}

.searchContent {
  display: flex;
}

.searchLabel {
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #F1F2F2;
  min-width: 122px;
  padding-top: 10px;
}

.searchLabel.optionsLabel {
  align-items: center;
  height: 20px;
  display: flex;
  padding-top: 31px;
}

.searchContent .form-control {
  background-color: #081B27;
  margin-bottom: 20px;
  width: 89%;
}

#userNameBox {
  background: #09202D !important;
  width: 100%;
  -webkit-text-fill-color: #8FA8B8 !important;
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  caret-color: white;
  border-radius: 4px;
  border: none;
  padding: 11px 18px;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
}

#userNameBox::placeholder {
  color: #8FA8B8 !important;
  opacity: 1 !important;
}

.dateInput {
  margin-bottom: 20px;
  width: 89%;
  color: #CFDEE8;
  background-color: #081B27;
  border-radius: 4px;
  height: 45px;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 25%) inset;
  border: none;
  padding: 11px 18px;
  font-family: Jost-Regular, Sans-serif;
  font-size: 16px;
}

.startDay {
  margin-right: 20px;
}

.searchContent .react-datepicker-wrapper:first-of-type {
  margin-right: 20px;
}

.searchCheckBoxContainer {
  padding-top: 31px;
  display: flex;
  margin-right: 5px;
}

.searchCheckBoxContainer .MuiCheckbox-root {
  padding: 0px;
  margin-right: 5px !important;
  margin-top: 1px;
}

.searchCheckText {
  margin-left: 0px !important;
}

.searchCheckText .MuiTypography-root {
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #F1F2F2;
  margin-right: 18px;
  margin-top: 3px;
}

.searchBtn {
  margin: 25px 25px 32px !important;
}

.arrowDown {
  content: "";
  border: solid white;
  padding: 5px;
  border-width: 0 2px 2px 0px;
  transform: rotate(45deg);
  display: inline-block;
  height: 0px;
  width: 0px;
  margin-top: 14px;
  margin-left: -43px;
  margin-right: 11px;
}

.arrow1 {
  margin-left: -59px;
}

.react-datepicker__day {
  color: #8FA8B8;
}

.react-datepicker__month-container {
  background: #081B27;
}

@media screen and (min-width: 991px) and (max-width: 1400px) {
  .searchBarContainer {
    width: 592px;
  }
}

.downArrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/*CustomSearch End*/

.CustomConfirmationBox {
  width: auto;
  background-color: #13344A !important;
  color: #F1F2F2 !important;
  position: relative;
  float: left;
  height: 33px;
  font-family: Jost-Regular, Sans-serif !important;
  font-size: 15px !important;
  top: 10px;
  margin-top: 10px;
}

.customCalendar .react-calendar {
  width: 23px;
  background: #081B27
}

.searchDateInput {
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  width: 167px;
  height: 45px;
  border: 0px;
  color: #8FA8B8;
  -webkit-tap-highlight-color: #8FA8B8;
  text-align: left;
  padding-left: 18px;
  background: #09202D;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.searchDateInput::placeholder {
  color: #8FA8B8;
  opacity: 1;
  /* Firefox */
}


.customCalendar .react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 0.5em 0.5em;
}

.customCalendar .react-calendar__month-view__days__day--weekend {
  color: #F1F2F2 !important;
}

.customCalendar .react-datepicker__current-month {
  display: none;
}

.customCalendar .react-datepicker__day-names {
  display: none;
}

.customCalendar .react-datepicker__header {
  background: #081B27 !important;
}

.customCalendar .react-datepicker__day {
  color: #F1F2F2 !important;
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;

}

.customCalendar .react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
}

.customCalendar .react-datepicker__month-dropdown-container {
  margin-right: 30px;
  color: #F1F2F2 !important;
}

.customCalendar .react-datepicker__year-dropdown-container {
  color: #F1F2F2 !important;
}

.customCalendar .react-datepicker__month-dropdown {
  background: #081B27 !important;
}

.customCalendar .react-datepicker__year-dropdown {
  background: #081B27 !important;
}

.customCalendar .react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background: #012540 !important;
  border-radius: 2px;
}

.customCalendar .react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background: #012540 !important;
}

.customCalendar .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: #F1F2F2 !important;
  border-width: 2px 2px 0 0 !important;
  width: 6px !important;
  height: 6px !important;
}

.customCalendar .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  top: 2px !important;
}

.customCalendar .react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background: #012540 !important;
}


.react-datepicker__navigation--years-upcoming {
  transform: rotate(315deg);
  border-color: #F1F2F2 !important;
  border-width: 2px 2px 0 0 !important;
  width: 1px !important;
  height: 1px !important;
  border: solid #F1F2F2 !important;
  display: inline-block !important;
  padding: 3px !important;
  margin-top: 14px;
  /* margin-bottom: 3px; */
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  border-left-color: #081B27 !important;
  border-bottom-color: #081B27 !important;
}

.react-datepicker__navigation--years-upcoming:hover {
  background-color: #022540 !important;
}

.react-datepicker__year-dropdown .react-datepicker__year-option:last-child {
  transform: rotate(135deg);
  border-color: #F1F2F2 !important;
  border-width: 2px 2px 0 0 !important;
  width: 2px !important;
  height: 1px !important;
  border: solid black;
  display: inline-block;
  padding: 3px;
  margin-top: 3px;
  margin-bottom: 10px;
  border-bottom-left-radius: 0rem;
  border-bottom-right-radius: 0rem;
}

.customCalendar .react-datepicker__day--selected {
  background: #012540 !important;
}

.customCalendar .react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: #000 !important;
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
}

.calendarContainer {
  display: flex;
  background: #081B27;
  width: 159px;
  /* justify-content: end; */
  position: relative;
  border: 1px solid white;
}

.displayFlex {
  display: flex;
}

#thisContainsAutocomplete .MuiAutocomplete-popper {
  background-color: transparent;
}

#userNameBox-popup {
  background: #081B27;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  color: #f1f2f2;
}

#thisContainsAutocomplete .MuiAutocomplete-option:hover,
#thisContainsAutocomplete .MuiAutocomplete-option:focus {
  background-color: #022540 !important;
}

#thisContainsAutocomplete .MuiPaper-root {
  background-color: transparent !important;
}

.MuiChip-deleteIcon {
  height: 9px !important;
  width: 9px !important;
}

/*CustomIconButton Start*/
.customIconBtn {
  border: none;
  background: #030F17;
  border-radius: 4px;
  height: 36px;
  width: 38px;
  margin-bottom: 2px;
  display: block;
  cursor: pointer;
}

.customIconBtnText {
  margin-left: 8px;
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #F1F2F2;
}


.customIconBtn:hover {
  width: 146px;
  background: #0D2738;
  border-radius: 0px 4px 4px 0px;
  padding: 10px 0px 10px 11px;
  text-align: left;
  align-items: center;
  display: flex;
}

.customRightIcon {
  height: 16px;
  width: 16px
}

/*CustomIconButton End*/

/* CustomTypography start*/
.customTypoText {
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #6A8393;
  margin: 36px auto;
}

/* CustomTypography End*/

/*CustomNoResult Start */
.noResultWrapper {
  background: #030F17;
  border: 1.6px solid #0F2C40;
  border-radius: 10px;
  height: 104px;
  width: 638px;
  margin: 32px auto;
  align-items: center;
  padding: 20px;
}

.noResultIcon {
  float: right;
  cursor: pointer;
}

.noResultText {
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #86A2B4;
  margin: 40px auto auto;

}

/*CustomNoResult End */

.MuiAlert-standardSuccess .MuiAlert-icon {
  color: #F1F2F2 !important;
}