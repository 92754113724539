.box-body {
    background-color: #030F17;
    box-shadow: 0px 0px 5px #000000;
    margin: 8px 0px !important;
    z-index: 1000;
}

.title-box {
    background-color: transparent;
    margin-top: 2px;
    margin-bottom: 2px;
    color: #86A2B4;
    font-family: Jost-Regular, Sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    padding-left: 15px;
    display: flex;
    border-radius: 4px;
    cursor: pointer;
}

.title-box:hover {
    background-color: #13344A;
    color: #FFFFFF
}

.menu-title {
    padding-left: 5px;
}

.title-icons {
    height: 16px !important;
    width: 16px !important;
    align-self: center;
    padding-right: 5px;
}