.frontbox{
  color: #f1f2f2;
  background-color: #030f17;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25) inset;
  border: none;
  padding: 2rem 2rem;
  font-family: Jost-Regular, Sans-serif;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  font-weight: 400;
  height: 200px;
}

.form-label{
  font-size: 18px;
  align-items: center;
}

.feedbackDiv{
  display: flex;
  background: #B00002;
  color: #F1F2F2;
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;  
  letter-spacing: 0.02em;
  text-transform: capitalize;
  padding: 11.5px 12px;
  border-radius: 4px;
  width: 120px;
}

.feedbackText{
  margin: 0px;
  height: 17px;
  padding-top: 2px;
}

.feedbackContainer .form-control .MuiInput-multiline {
  padding: 20px 20px 23px;
  color: #F1F2F2;
  background: #091E2A;
}

.feedbackContainer .addTitleBox .Mui-focused {
  background: #0D2738;
  margin-right: -0.5px;
}